<template>
	<ul class="flex gap-2 divide-x" aria-label="Social Media Links">
		<li v-for="link in socialLinks" :key="link.name" class="relative pl-2">
			<UTooltip
				:text="link.name"
				:popper="{arrow: true, placement: 'bottom', strategy: 'fixed'}"
				:ui="{base: '[@media(pointer:coarse)]:!block'}"
			>
				<NuxtLink
					:aria-label="link.name.toLowerCase()"
					:title="link.name.toLowerCase()"
					:to="link.url"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Icon :class="link.iconClass" :name="link.icon" />
				</NuxtLink>
			</UTooltip>
		</li>
	</ul>
</template>

<script setup lang="ts">
type SocialLink = {
	name: string;
	url: string;
	icon: string;
	iconClass?: string;
};

const socialLinks = ref<SocialLink[]>([
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/salvobeatohairandscience/',
		icon: 'jam:instagram',
		iconClass: 'h-5 w-5'
	},
	{
		name: 'Facebook',
		url: 'https://www.facebook.com/ModaStileParrucchieriDiSalvoBeato',
		icon: 'jam:facebook-square',
		iconClass: 'h-5 w-5'
	},
	{
		name: 'Tiktok',
		url: 'https://www.tiktok.com/@salvobeatoparrucchieri',
		icon: 'ic:baseline-tiktok',
		iconClass: 'h-5 w-5'
	}
]);
</script>
