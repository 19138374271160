<template>
	<body :class="{dark: darkMode}">
		<LayoutHeader />
		<main class="min-h-100vh text-gray-900 dark:bg-black dark:text-gray-50">
			<slot />
		</main>
		<LayoutFooter />
	</body>
</template>

<script setup lang="ts">
const darkMode = useState('darkMode', () => false);
</script>
